import { ComponentClass, useCallback, useState } from 'react';
import type { AppProps } from 'next/app';
import {
  DocumentationIcon,
  Layout,
  SciNetLogoDark,
  SciNetLogoLight,
  SupportIcon,
  useAuthentication,
  UserProfile,
  ProjectContextProvider,
  OrgContextProvider,
} from 'components';
import { useRouter } from 'next/router';
import cx from 'classnames';
import Link from 'next/link';
import {
  getFullProject,
  getOrganization,
  menuMap,
  ORGANIZATIONS_INDEX_CANISTER_ID,
} from '../../lib';
import { useProfileContext } from '../Profile';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { useUserContext } from '../User';
import { useAppContext } from '../AppContext';
import { InquiriesContextProvider } from '../../contexts';
import { LicensesContextProvider } from '../../contexts/LicensesContext';
import { Done } from '../Done';

export default function Main({ Component, pageProps }: Partial<AppProps>) {
  const router = useRouter();
  const { identity, isAuthenticated, logout } = useAuthentication();
  const { orgActor, orgTeamActor, projectActor, projectsMilestonesActor } =
    useAppContext();
  const { clearUser } = useUserContext();
  const { clearProfile } = useProfileContext();
  const [secondColumnOpen, setSecondColumnOpen] = useState(false);
  const { user } = useUserContext();

  const logoLight = <SciNetLogoLight className="h-8 mt-2" />;
  const logoDark = <SciNetLogoDark className="ml-auto h-6 mt-2" />;

  const logoutClick = useCallback(() => {
    clearUser();
    clearProfile();
    logout();
  }, [clearProfile, clearUser, logout]);

  const footerNode = [
    {
      text: 'Support',
      href: 'https://scinet.atlassian.net/servicedesk/customer/portal/1',
      // @ts-ignore
      Icon: SupportIcon as ComponentClass<any>,
      onClick: null,
    },
    {
      text: 'Documentation',
      href: 'https://scinet.notion.site/Welcome-to-SCINET-8809a679ded14820b660e841bd96f659',
      // @ts-ignore
      Icon: DocumentationIcon as ComponentClass<any>,
      onClick: null,
    },
    isAuthenticated && {
      text: 'Log Out',
      onClick: logoutClick,
      // @ts-ignore
      Icon: ArrowRightOnRectangleIcon as ComponentClass<any>,
      href: '/',
    },
  ];
  isAuthenticated
    ? {
        text: 'Log Out',
        onClick: logoutClick,
        // @ts-ignore
        Icon: ArrowRightOnRectangleIcon as ComponentClass<any>,
        href: '/',
      }
    : null;

  // const secondColumnNode = (
  //   <Wallet
  //     secondColumnOpen={secondColumnOpen}
  //     setSecondColumnOpen={setSecondColumnOpen}
  //   />
  // );

  // const actionsNode = (
  //   <>
  //     {user && (
  //       <Button
  //         className="fixed right-12 md:right-16"
  //         color="tertiary"
  //         onClick={() => setSecondColumnOpen!(!secondColumnOpen)}>
  //         <WalletIcon className="h-4 w-4" />
  //       </Button>
  //     )}
  //   </>
  // );

  return <Done />;
}
